import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import GameList from '../components/games/gameList';
import ContentListItem from '../components/contentListItem';
import ContentfulPage from '../components/contentfulPage';
import ContentfulRichText from '../components/contentfulRichText';
import StandardPage from './standardPage';
import Carousel from '../components/carousel';
import Seo from '../components/seo';
import AffTable from '../components/affTable';


const promoUrls = {
  '1-777bay-home-banner': {
    'en': 'https://www.luckystar.io/',
    'es': 'https://www.luckystar.io/',
    'de': 'https://www.luckystar.io/',
    'pt': 'https://www.luckystar.io/'
  },
  '2-777bay-home-banner-2': {
    'en': '/cryptocurrency-casino/',
    'es': '/criptomoneda-casino/',
    'de': '/kryptowährung/',
    'pt': '/bonus-de-cassino/criptomoeda/'
  },
  '3-777bay-home-banner-3': {
    'en': '/casino-bonuses/2nd-deposit/',
    'es': '/casino-bonos/2do-deposito/',
    'de': '/casino-bonusses/2-einzahlung/',
    'pt': '/bonus-de-cassino/2-deposito/'
  }
};

const IndexPage = props => {
  const { t, i18n: { language } } = useTranslation();
  const {
    contentfulPage,
    alternateLanguagePages,
    newGames,
    jackpotGames,
    featuredPromos,
    promoPage,
    latestBlogs,
    blogPage,
    homeHeros
  } = props.data;

  return (
    <StandardPage topPageData={false} showAffTable={false} {...props}>
      <Seo meta={[
        {
          name: 'google-site-verification',
          content: 'CyiyZFw1aR7l71liMYatEKFCnxmrJHmEIJm5n_pBYzk'
        }
      ]} />
      <Carousel
        itemsPerSlide={1}
        hideDots
        arrows
        arrowsClass=""
      >
        {homeHeros.edges.map(({ node: { childImageSharp, name } }, i) => (
          <div key={i} className="w-full"><Link to={promoUrls[name][language]}><Img fluid={childImageSharp.fluid} alt="" /></Link></div>
        ))}
      </Carousel>
      <AffTable />
      <p className="h3 text-primary mb-4 sm:mb-5 accented">{t('newGames')}</p>
      <GameList games={newGames} className="mb-16" />
      <p className="h3 text-primary mb-4 sm:mb-5 accented">{t('jackpotGames')}</p>
      <GameList games={jackpotGames} className="mb-16" />
      <div className="centeredContentWrapper">
        <ContentfulPage data={contentfulPage} alternateLanguagePages={alternateLanguagePages} />
        {latestBlogs && latestBlogs.edges && (
          <div className="mt-20">
            <p className="h3 text-primary mb-10 sm:mb-11 accented">{t('latestBlogs')}</p>
            {latestBlogs.edges.map(({ node: { title, summary, slug, hero } }) => {
              const url = `/${blogPage.url}/${slug}/`;

              return (
                <ContentListItem
                  key={slug}
                  title={title}
                  content={<ContentfulRichText raw={summary.raw} />}
                  image={(hero && <Link to={url}><Img fluid={hero.fluid} alt={hero.alt || ''} /></Link>) || null}
                  detailsButtonText={t('expandContentButton')}
                  url={url}
                />
              );
            })}
          </div>
        )}
      </div>
    </StandardPage>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $contentImgMaxWidth: Int!, $imgQuality: Int!, $key: String!, $gameSkip: Int!, $language: String!) {
    newGames: allBogGame(skip: $gameSkip, limit: 10, filter: {isNew: {eq: true}, isMobile: {eq: true}}, sort: {order: ASC, fields: position}) {
      edges {
        node {
          slug
          title
          images {
            filled {
              url
            }
          }
          provider
          category
          position
        }
      }
    }
    jackpotGames: allBogGame(skip: $gameSkip, limit: 10, filter: {isMobile: {eq: true}, hasJackpot: {eq: true}}, sort: {order: ASC, fields: position}) {
      edges {
        node {
          slug
          title
          images {
            filled {
              url
            }
          }
          provider
          category
          position
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      summary {
        raw
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
    featuredPromos: allContentfulPromo(filter: {featured: {eq: true}, language: {code: {eq: $language}}}, sort: {order: ASC, fields: position}) {
      edges {
        node {
          title
          slug
          summary {
            raw
          }
          hero {
            fluid(
              maxWidth: $headerImageMaxWidth,
              quality: $imgQuality
            ) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
        }
      }
    }
    promoPage: contentfulPage(key: {eq: "promo"}, language: {code: {eq: $language}}) {
      url
    }
    latestBlogs: allContentfulBlog(filter: {language: {code: {eq: $language}}}, sort: {order: DESC, fields: createdAt}, skip: 0, limit: 2) {
      edges {
        node {
          title
          slug
          summary {
            raw
          }
          hero {
            fluid(
              maxWidth: $headerImageMaxWidth,
              quality: $imgQuality
            ) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
        }
      }
    }
    blogPage: contentfulPage(key: {eq: "blog"}, language: {code: {eq: $language}}) {
      url
    }
    homeHeros: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {glob: "index-hero/*"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
          name
        }
      }
    }
  }
`;
